<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Edit Campaign</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingCampaign">
              <ValidationObserver
                v-if="campaign"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateCampaign)"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <TextInput
                        v-model="campaign.title"
                        name="title"
                        rules="required"
                        label="Title"
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <ValidationProvider
                          v-slot="{ failed, errors }"
                          rules="required"
                          name="description"
                        >
                          <label>Description</label>
                          <textarea
                            v-model="campaign.description"
                            name="description"
                            class="form-control"
                            rows="3"
                            :class="{ 'is-invalid': failed }"
                          ></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingCampaign: false,
      campaign: null,
      loadingUpdate: false,
      subscribed: false,
      loadingPassword: false,
      loadingTwilio: false,
      twilioConfig: {
        sid: null,
        token: null,
      },
      password: {
        password: '',
        password_confirmation: '',
      },
      loadingImpersonate: false,
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getCampaign()
  },

  methods: {
    updateCampaign() {
      this.loadingUpdate = true

      this.$store
        .dispatch('campaign/update', this.campaign)
        .then(() => {
          this.loadingUpdate = false
          this.$router.push({ name: 'business.campaigns.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getCampaign() {
      this.loadingCampaign = true

      this.$store
        .dispatch('campaign/find', this.$route.params.id)
        .then((campaign) => {
          this.campaign = campaign
          this.loadingCampaign = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.campaigns.index' })
          this.loadingCampaign = false
        })
    },
  },
}
</script>
